<template>
  <v-container v-if="frontMatter">
    <v-row dense>
      <v-col>
        <h2 align="center" class="ma-4 text-uppercase font-weight-light">
          {{ frontMatter.attributes.title }}
        </h2>
        <div class="html-container" v-html="html" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import fm from 'front-matter';
import md from 'markdown-it';
import Prism from 'prismjs';

import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-batch';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-csharp';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-xml-doc';

export default {
  data() {
    return {
      frontMatter: null,
      html: null
    };
  },
  props: {
    slug: {
      type: String,
      default: 'THIS-POST-DOES-NOT-EXIST'
    }
  },
  updated() {
    Prism.highlightAll();
  },
  created() {
    this.$http.get(`/blog/${this.slug}/readme.md`).then(response => {
      this.frontMatter = fm(response.data);
      this.html = new md().render(this.frontMatter.body);

      // <h1></h1> Strip h1 tag because title is already displayed on the top of the page
      this.html = this.html.replace(/<h1.*>.*?<\/h1>/gi, '');

      Prism.highlightAll();
    });
  },
  metaInfo() {
    var title = 'Ralf de Rijcke | ' + this.frontMatter?.attributes.title;
    var description = this.frontMatter?.attributes.description;
    //var thumbnail = 'https://UNKNOWN';

    return {
      title: title,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: title
        },
        // Twitter Card
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:title', content: title },
        { name: 'twitter:description', content: description },
        // image must be an absolute path
        //{ name: "twitter:image", content: url + this.post.thumbnail },
        // Facebook OpenGraph
        { property: 'og:title', content: title },
        { property: 'og:site_name', content: 'Ralf de Rijcke' },
        { property: 'og:type', content: 'website' },
        //{ property: "og:image", content: url + this.post.thumbnail },
        { property: 'og:description', content: description }
      ],
      link: [
        {
          rel: 'canonical',
          href: 'https://www.ralfderijcke.nl' + this.$route.path
        }
      ]
    };
  }
  // head() {
  //   //const url = "https://www.ralfderijcke.nl";
  //   return {
  //     title: this.post.title,
  //     meta: [
  //       // Twitter Card
  //       { name: "twitter:card", content: "summary" },
  //       { name: "twitter:title", content: this.frontMatter.title },
  //       { name: "twitter:description", content: this.frontMatter.description },
  //       // image must be an absolute path
  //       //{ name: "twitter:image", content: url + this.post.thumbnail },
  //       // Facebook OpenGraph
  //       { property: "og:title", content: this.frontMatter.title },
  //       { property: "og:site_name", content: "Ralf de Rijcke" },
  //       { property: "og:type", content: "website" },
  //       //{ property: "og:image", content: url + this.post.thumbnail },
  //       { property: "og:description", content: this.frontMatter.description },
  //     ],
  //   };
  // },
};
</script>

<style lang="css" scoped>
@import '../themes/prism-vs.css';

.html-container >>> img {
  max-width: 100%;
}

.html-container >>> p,
.html-container >>> pre,
.html-container >>> blockquote {
  margin: 12px 0;
}

.html-container >>> h2,
.html-container >>> h3,
.html-container >>> h4 {
  margin: 24px 0 12px 0;
}

.html-container >>> blockquote p {
  margin: 0;
  padding: 5px 10px 5px 10px;
}

.html-container >>> blockquote {
  border-left: 5px solid #2196f3;
  font-size: 0.9em;
  background-color: #eeeeee;
}
</style>
